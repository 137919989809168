import React, { useContext } from "react";
import Logo from "../../Images/New/mhostA/LogoBlack.svg";
import hero from "../../Images/New/mhostB/hero.png";
import heroMob from "../../Images/New/mhostB/heroMob.png";

import { useLanguage } from "../../Helpers/LanguageContext";

const HeroSectionMhostB = ({ }) => {

  const {languageData} = useLanguage();

  return (
    <div className="w-full bg-white rounded-b-[50px] rounded-bl-[50px] pb-6 md:py-[70px]">
      <div className="container ">
        <div className="w-[111px]  hidden md:flex">
          <img src={Logo} alt="Logotype" />
        </div>
        <div className="w-[64px] pt-[22px] md:pt-[30px] md:hidden block m-auto">
          <img src={Logo} alt="Logotype" />
        </div>
        <p className="text-[18px] md:text-[20px] font-bold text-[#8649D1] text-center  my-3 md:my-0 md:pt-[50px]">{languageData.deeper2?.MHostBRealConversations}</p>
        <p className="text-[32px] md:text-[43px] text-[#00063D] leading-[32px] md:leading-[52px] text-center  font-bold mt-2">{languageData.deeper2?.MHostBTitle}</p>
        <p className="font-normal text-[14px] md:text-[18px] text-[#80839E] leading-[19px] md:leading-[27px] mt-[10px] md:mt-[30px] pb-6 text-center m-auto max-w-[680px]">{languageData.deeper2?.MHostBSubtitle}</p>
        <img className="w-full mt-12 hidden md:block" src={hero} alt="hero" />
        <img className="w-full mt-4 md:hidden block" src={heroMob} alt="hero" />
        
      </div>

    </div>
  );
};

export default HeroSectionMhostB;
