import React from "react";
import { useLanguage } from "../../Helpers/LanguageContext";

const StartSection = () => {
  const { languageData } = useLanguage();

  return (
    <>
      <div className="w-full bg-startBgMob md:bg-startBg bg-contain sm:bg-cover bg-no-repeat h-[80vh] flex items-center">
        <div className="container">
          <div className="max-w-[550px] w-full pt-[100px] relative leading-[55px] font-bold inter">
            <p className="text-[18px] md:text-[20px] font-bold text-white text-center md:text-left leading-[38px]">
              {languageData.homepage?.StartSectionTitle}
            </p>
            <h1 className="text-[24px] md:text-[36px] text-[#8649D1] text-center md:text-left leading-[28px] md:max-w-[300px] max-w-[unset] md:leading-[43px]">
              {languageData.homepage?.StartSectionTitleSpan}
            </h1>
            <h4 className="inter font-normal text-[14px] md:text-[20px] text-[#c2c2c2] leading-[19px] md:leading-[33px] mt-2 md:mt-[15px] pb-4 md:pb-12 max-w-[600px] text-center md:text-left">
              {languageData.homepage?.StartSectionSubtitle}
            </h4>
            <a href="https://app.morgis.com/auth/signup-guest/?email=1" target="_blank">
              <button className="max-w-[350px] w-full m-auto md:m-[unset] h-[50px] flex justify-center items-center text-white bg-[#8649D1] text-[16px] font-semibold rounded-[100px]">
                {languageData.homepage?.StartConnections}
                <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 16L0 8L1.4 6.575L7 12.175L7 0L9 0L9 12.175L14.6 6.575L16 8L8 16Z" fill="white" />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartSection;
