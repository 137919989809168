import React, { useState } from "react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from 'swiper/react';

import logo from "../../Images/Logo.png";
import sliderImage1 from '../../Images/New/InstB/avatar1.png';
import sliderImage2 from '../../Images/New/InstB/avatar2.png';
import sliderImage3 from '../../Images/New/InstB/avatar3.png';
import sliderImage4 from '../../Images/New/InstB/avatar4.png';
import sliderImage5 from '../../Images/New/InstB/avatar5.png';
import CardsSliderMob from "./CardsSliderMob";
import { useLanguage } from "../../Helpers/LanguageContext";
import { Link } from "react-router-dom";

const HeroSectionInst = () => {

  const { languageData } = useLanguage();

  const [activeIndex, setActiveIndex] = useState(0);

  const data = [
    {
      img: sliderImage1,
      name: languageData.getconnected?.instBSlide1Name,
      quote: languageData.getconnected?.instBSlide1Role,
      interests: languageData.getconnected?.instBSlide1Desc,
      hobbies: languageData.getconnected?.instBSlide1Hobby,
      nameBtn: 'Nataly',
      link: 'https://www.morgis.com/natalysaleemahh?email=1'
    },
    {
      img: sliderImage2,
      name: languageData.getconnected?.instBSlide2Name,
      quote: languageData.getconnected?.instBSlide2Role,
      interests: languageData.getconnected?.instBSlide2Desc,
      hobbies: languageData.getconnected?.instBSlide2Hobby,
      nameBtn: 'Ramin',
      link: 'https://www.morgis.com/raminhoodeh?email=1'

    },
    {
      img: sliderImage3,
      name: languageData.getconnected?.instBSlide3Name,
      quote: languageData.getconnected?.instBSlide3Role,
      interests: languageData.getconnected?.instBSlide3Desc,
      hobbies: languageData.getconnected?.instBSlide3Hobby,
      nameBtn: 'Mariah',
      link: 'https://www.morgis.com/mariahcariucha?email=1'
    },
    {
      img: sliderImage4,
      name: languageData.getconnected?.instBSlide4Name,
      quote: languageData.getconnected?.instBSlide4Role,
      interests: languageData.getconnected?.instBSlide4Desc,
      hobbies: languageData.getconnected?.instBSlide4Hobby,
      nameBtn: 'Adriel',
      link: 'https://www.morgis.com/adrielmiranda625?email=1'
    },
    {
      img: sliderImage5,
      name: languageData.getconnected?.instBSlide5Name,
      quote: languageData.getconnected?.instBSlide5Role,
      interests: languageData.getconnected?.instBSlide5Desc,
      hobbies: languageData.getconnected?.instBSlide5Hobby,
      nameBtn: 'Caroline',
      link: 'https://www.morgis.com/carolmuller?share=true'
    },
    {
      img: sliderImage1,
      name: languageData.getconnected?.instBSlide1Name,
      quote: languageData.getconnected?.instBSlide1Role,
      interests: languageData.getconnected?.instBSlide1Desc,
      hobbies: languageData.getconnected?.instBSlide1Hobby,
      nameBtn: 'Nataly',
      link: 'https://www.morgis.com/natalysaleemahh?email=1'
    },
    {
      img: sliderImage2,
      name: languageData.getconnected?.instBSlide2Name,
      quote: languageData.getconnected?.instBSlide2Role,
      interests: languageData.getconnected?.instBSlide2Desc,
      hobbies: languageData.getconnected?.instBSlide2Hobby,
      nameBtn: 'Ramin',
      link: 'https://www.morgis.com/raminhoodeh?email=1'

    },
    {
      img: sliderImage3,
      name: languageData.getconnected?.instBSlide3Name,
      quote: languageData.getconnected?.instBSlide3Role,
      interests: languageData.getconnected?.instBSlide3Desc,
      hobbies: languageData.getconnected?.instBSlide3Hobby,
      nameBtn: 'Mariah',
      link: 'https://www.morgis.com/mariahcariucha?email=1'
    },
    {
      img: sliderImage4,
      name: languageData.getconnected?.instBSlide4Name,
      quote: languageData.getconnected?.instBSlide4Role,
      interests: languageData.getconnected?.instBSlide4Desc,
      hobbies: languageData.getconnected?.instBSlide4Hobby,
      nameBtn: 'Adriel',
      link: 'https://www.morgis.com/adrielmiranda625?email=1'
    },
    {
      img: sliderImage5,
      name: languageData.getconnected?.instBSlide5Name,
      quote: languageData.getconnected?.instBSlide5Role,
      interests: languageData.getconnected?.instBSlide5Desc,
      hobbies: languageData.getconnected?.instBSlide5Hobby,
      nameBtn: 'Caroline',
      link: 'https://www.morgis.com/carolmuller?share=true'
    },
  ];

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  return (
    <div className="max-w-[1100px] m-auto py-[20px] md:py-[70px]">
      <div className="m-auto px-2">
        <div className="m-auto w-[64px] md:w-[111px]">
          <img src={logo} alt="Logotype" />
        </div>
        <p className="text-[18px] md:text-[30px] font-bold text-[#8649D1] text-center  pt-4 md:pt-16">{languageData.deeper2?.MHostBRealConversations}</p>
        <p className="text-[32px] md:text-[48px] font-bold text-center text-white max-w-[930px] m-auto leading-[38px] md:leading-[60px] ">
          {languageData.getconnected?.HeroSectionInstTitle}
        </p>
        <p className="text-[14px] md:text-[24px] font-normal text-center text-[#B2B4C4] max-w-[680px] m-auto leading-[19px] md:leading-[30px] pt-3">
          {languageData.getconnected?.HeroSectionInstSubtitle}
        </p>
        <div className="lg:hidden max-w-[350px] md:max-w-[550px] m-auto w-full relative left-0 mt-6 md:mt-0 my-7">
          <CardsSliderMob />
        </div>
        <div className="my-12 hidden lg:block">
          <Swiper
            spaceBetween={-100}
            slidesPerView={4.3}
            centeredSlides={true}
            modules={[EffectCoverflow, Pagination, Navigation]}
            loop={true}
            effect="coverflow"
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            onSlideChange={handleSlideChange}
            className="custom-swiper"
          >
            {data.map((slide, index) => (
              <SwiperSlide key={index} style={{ width: '300px' }}>
                <div className={` bg-white p-2 rounded-[24px] mx-auto w-[300px]`}>
                  <img className='w-full rounded-[20px]' src={slide.img} alt="avatar" />
                  <div className='px-2'>
                    <p className='text-[#00063D] text-[20px] font-semibold pt-2'>{slide.name}</p>
                    <p className='text-[#00063db7] text-[16px] font-normal leading-5 pt-2 truncate w-[260px]'>{slide.quote}</p>
                    <div className='flex items-center pt-2'>
                      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.97508 17.9835L5.67751 17.8318L5.35075 17.9011L1.25546 18.7694L2.15162 14.9231L2.23462 14.5668L2.0558 14.2478C1.32225 12.9389 0.941125 11.4803 0.94346 10.0015V10C0.94346 5.03948 5.17178 0.943448 10.4828 0.943448C15.7938 0.943448 20.0221 5.03948 20.0221 10C20.0221 14.9605 15.7938 19.0566 10.4828 19.0566L10.4814 19.0566C8.90736 19.0588 7.35908 18.6894 5.97508 17.9835Z" stroke="url(#paint0_linear_50_72)" stroke-width="1.8869" />
                        <path d="M5.97508 17.9835L5.67751 17.8318L5.35075 17.9011L1.25546 18.7694L2.15162 14.9231L2.23462 14.5668L2.0558 14.2478C1.32225 12.9389 0.941125 11.4803 0.94346 10.0015V10C0.94346 5.03948 5.17178 0.943448 10.4828 0.943448C15.7938 0.943448 20.0221 5.03948 20.0221 10C20.0221 14.9605 15.7938 19.0566 10.4828 19.0566L10.4814 19.0566C8.90736 19.0588 7.35908 18.6894 5.97508 17.9835Z" stroke="#00063D" stroke-width="1.8869" />
                        <defs>
                          <linearGradient id="paint0_linear_50_72" x1="10.4828" y1="20" x2="10.4828" y2="0" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#8649D1" />
                            <stop offset="0.385417" stopColor="#8B6DE8" />
                            <stop offset="1" stopColor="#8F92FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <p className='text-[#00063db7] text-[16px] font-medium leading-5 ml-2 truncate w-[240px]'>{slide.interests}</p>
                    </div>
                    <div className='flex items-center pt-2'>
                      <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_50_74)">
                          <path d="M6.44269 7.77111V7.55172H5.39442H2H0.951724V8.6V19V20.0483H2H5.39442H6.04335H6.44269H7.09163H15.5777C16.1158 20.0483 16.6185 19.8907 17.0689 19.6075C17.5022 19.3353 17.8659 18.9686 18.0936 18.4916L18.0984 18.4815L18.103 18.4713L20.6477 12.8338C20.6479 12.8333 20.6482 12.8327 20.6484 12.8322C20.7258 12.6617 20.781 12.4848 20.8166 12.3045C20.8493 12.1414 20.869 11.9723 20.869 11.8V10.2C20.869 9.45595 20.5584 8.81476 20.0306 8.31724C19.5033 7.8201 18.8524 7.55172 18.1235 7.55172H14.8651L15.4445 5.39159L15.4803 5.25815V5.12V4.84C15.4803 4.5404 15.4154 4.25596 15.3116 3.99403L15.3105 3.99106C15.2045 3.72594 15.0471 3.45917 14.8117 3.23724L13.7509 2.23724L13.0319 1.55934L12.3128 2.23724L6.44269 7.77111ZM14.3371 4.37998L14.337 4.38L14.3371 4.37998Z" stroke="#00063D" stroke-width="2.09655" />
                        </g>
                        <defs>
                          <clipPath id="clip0_50_74">
                            <rect width="22" height="21" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p className='text-[#00063db7] text-[16px] font-medium leading-5 ml-2 truncate w-[200px]'>{slide.hobbies}</p>
                    </div>
                    <Link target="_blank" to={slide.link}>
                      <button className="w-full h-[45px] flex justify-center items-center text-white bg-[#8649D1] text-[16px] font-semibold rounded-[100px] mt-5 mb-3">{languageData.getconnected?.ConnectWith} {slide.nameBtn.split(',')[0]}</button>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionInst;
