import React from 'react'
import heroImage from "../../Images/heroImage.webp";
import heroImageMobile from "../../Images/heroImageMobile.webp";
import HeroSectionNew from '../../Components/InstA/HeroSectionNew';
import StartSection from '../../Components/InstA/StartSection';
import RecipeSectionNew from '../../Components/InstA/RecipeSectionNew';
import ConnectSection from '../../Components/InstA/ConnectSection';


function InstA() {
    return (
        <div>
            <HeroSectionNew image={heroImage} mobileImage={heroImageMobile} />
            <StartSection />
            <ConnectSection />
            <div className="block-white"></div>
            <RecipeSectionNew />
        </div>
    )
}

export default InstA
