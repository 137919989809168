import React from "react";
import "../RecipeSection/RecipeSection.css";
import recipeImg from "../../Images/recipeImage.webp";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

import { useLanguage } from "../../Helpers/LanguageContext";

const RecipeSectionNew = ({ text }) => {

  const { languageData } = useLanguage();

  return (
    <div className="recipe-section" id="recipe-section">
      <div className="recipe-section-text">
        <h1>{languageData.homepage?.SignUpTitle}</h1>
        <a href="https://app.morgis.com/auth/signup-guest/?email=1" target="_blank">
          {languageData.homepage?.JoinNow}
        </a>
      </div>
      <div className="recipe-section-image">
        <img src={recipeImg} alt="Recipe" />
      </div>
      <div className="recipe-section-text-mobile ">
        <h1 className="text-center text-[24px] font-bold text-[#00063D] my-2 leading-[28px]">{languageData.homepage?.SignUpTitle}</h1>
        <a className="max-w-[350px] w-full m-auto text-[16px] font-semibold text-[#8649D1] h-[50px] flex justify-center items-center border border-[#8649D1] rounded-[100px] mb-2" href="" target="_blank">
        {languageData.homepage?.JoinNow}
        </a>
      </div>
    </div>
  );
};

export default RecipeSectionNew;
