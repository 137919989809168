import React from "react";
import logo from "../../Images/New/mhostA/LogoBlack.svg";
import mobileLogo from "../../Images/New/mhostA/LogoBlack.svg";
import heroImageEn from "../../Images/New/mhostA/hero.png";
import heroImagePT from "../../Images/New/mhostA/heroPt.png";
import heroImageES from "../../Images/New/mhostA/heroEs.png";
import arrowDark from "../../Images/New/mhostA/arrowDark.svg";
import { useLanguage } from "../../Helpers/LanguageContext";

const HeroSectionMhost = () => {
  const { languageData } = useLanguage();
  let heroImage;
  const { language } = useLanguage();
 
  console.log("Current Language: ", language);

  switch (language) {
    case 'en':
      heroImage = heroImageEn;
      break;
    case 'pt':
      heroImage = heroImagePT;
      break;
    case 'es':
      heroImage = heroImageES; 
      break;
    default:
      heroImage = heroImageEn;
  }


  return (
    <div className="w-full bg-white rounded-b-[50px] rounded-bl-[50px]">
      <div className="container">
        <div className="w-[111px] pt-[50px] hidden md:flex">
          <img src={logo} alt="Logotype" />
        </div>
        <div className="w-[64px] pt-[20px] md:hidden block m-auto">
          <img src={mobileLogo} alt="Logotype" />
        </div>
        <div className="max-w-[540px] w-full md:pt-[100px] pb-[20px] md:pb-[190px] relative font-bold inter z-10">
          <p className="text-[18px] md:text-[20px] font-bold text-[#8649D1] text-center md:text-left my-3 md:my-0">
            {languageData.deeper?.HeroSectionMhostIntro}
          </p>
          <h1 className="text-[32px] md:text-[43px] text-[#00063D] leading-[32px] md:leading-[52px] text-center md:text-left">
            {languageData.deeper?.HeroSectionMhostTitle}
          </h1>
          {
            languageData.Language === 'en' &&
            <h4 className="inter font-normal text-[14px] md:text-[18px] text-[#80839E] leading-[19px] md:leading-[27px] mt-[10px] md:mt-[30px] pb-6 text-center md:text-left">
              {languageData.deeper?.HeroSectionMhostSubtitle}
            </h4>
          }
          <h4 className="inter font-normal text-[14px] md:text-[18px] text-[#80839E] leading-[19px] md:leading-[27px] mt-[10px] md:mt-[30px] pb-6 text-center md:text-left">
            {languageData.deeper?.HeroSectionMhostSubtitle}
          </h4>
          <a href="https://app.morgis.com/auth/signup-guest/?email=1" target="_blank" rel="noopener noreferrer">
            <button className="max-w-[350px] w-full m-auto md:m-[unset] h-[50px] flex justify-center items-center text-white bg-[#8649D1] text-[16px] font-semibold rounded-[100px]">
              {languageData.deeper?.JoinNow}
            </button>
          </a>
          <div className="justify-end w-full mt-8 hidden md:flex">
            <img className="md:mr-[-100px]" src={arrowDark} alt="arrowDark" />
          </div>
        </div>
        <div className="lg:absolute right-0 top-[150px] z-0">
          <img className="lg:max-w-[650px] w-full" src={heroImage} alt="Hero Section Image" />
        </div>
        <div className="hero-section-image-mobile">
          <img src={heroImage} alt="Hero Section Image" />
        </div>
      </div>
    </div>
  );
};

export default HeroSectionMhost;
