import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from "./Menu/Menu";
import InstA from "./Pages/New/InstA";
import MhostA from "./Pages/New/MhostA";
import InstB from "./Pages/New/InstB";
import MhostB from "./Pages/New/MhostB";
import { LanguageProvider } from "./Helpers/LanguageContext";

function App() {

  return (

    <LanguageProvider>
      <>
        {
          <Menu />
        }

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<InstA />} />
            <Route path="/deeper" element={<MhostA />} />
            <Route path="/getconnected" element={<InstB />} />
            <Route path="/deeper2" element={<MhostB />} />
          </Routes>
        </BrowserRouter>
      </>
    </LanguageProvider>
  );
}

export default App;
