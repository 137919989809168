import React from "react";
import seekImage from "../../Images/seekImage.webp";
import { useLanguage } from "../../Helpers/LanguageContext";
import { Link } from "react-router-dom";

const SeekSectionInst = () => {
  const { languageData } = useLanguage();
  return (
    <div className="SeekSectionInstBg py-[40px] md:py-[100px]">
      <div className="container md:flex items-center justify-between">
        <div className="max-w-[335px] w-full">
          <img className="w-full" src={seekImage} alt="Seek Image" />
        </div>
        <div className="max-w-[555px] mt-6 md:mt-0">
          <p className="text-[24px] md:text-[36px] text-[#8649D1] font-bold leading-[28px] md:leading-[40px] text-center md:text-left">{languageData.getconnected?.SignUpNow}</p>
          <Link to="https://app.morgis.com/auth/signup-guest/?email=1" className="max-w-[350px] w-full m-auto mt-4 md:mt-6">
            <button className="flex justify-center items-center text-[16px] font-semibold text-[#00063D] bg-[#FDBA0D] max-w-[350px] w-full h-[50px] rounded-[100px] mt-6 md:mt-8">
              {languageData.getconnected?.LetsGo}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SeekSectionInst;
