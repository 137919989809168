import React from 'react';
import HeroSectionInst from '../../Components/InstB/HeroSectionInst';
import SearchSection from '../../Components/InstB/SearchSection';
import SeekSectionInst from '../../Components/InstB/SeekSectionInst';


function InstB() {
    return (
        <div>
            <HeroSectionInst  />
            <div className="block-white"></div>
            <SearchSection />
            <SeekSectionInst />
        </div>
    )
}

export default InstB
