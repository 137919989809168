import React, { useContext } from "react";
import { useLanguage } from "../../Helpers/LanguageContext";
import recipeImg from "../../Images/recipeImage.webp";
import avatar1 from "../../Images/New/instA/slider1.png";
import avatar2 from "../../Images/New/instA/slider2.png";
import avatar3 from "../../Images/New/instA/slider3.png";
import brackets from "../../Images/New/InstB/brackets.svg";
import { Link } from "react-router-dom";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

const SearchSection = ({ text }) => {
  const { languageData } = useLanguage();

  return (
    <div className="recipe-section" id="recipe-section">
      <div className="recipe-section-text">
        <h1 >{languageData.getconnected?.SearchSectionTitle}</h1>
        <p className="text-[20px] font-normal text-[#80839E]">{languageData.getconnected?.SearchSectionSubtitle}</p>
        <a className="mt-4" href="https://app.morgis.com/auth/signup-guest/?email=1" target="_blank" rel="noopener noreferrer">
          {languageData.getconnected?.DiscoverMore}
          <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8L8 16L6.575 14.6L12.175 9L0 9L0 7L12.175 7L6.575 1.4L8 0L16 8Z" fill="#8649D1" />
          </svg>
        </a>
      </div>
      <div className="recipe-section-image">
        <img src={recipeImg} alt="Recipe" />
      </div>
      <div className="recipe-section-text-mobile">
        <h1 className="text-[24px] leading-[28px] text-[#00063D] font-bold text-center mt-2">{languageData.getconnected?.SearchSectionTitle}</h1>
        <p className="text-[14px] font-normal text-[#80839E] leading-[19px] text-center py-4">{languageData.getconnected?.SearchSectionSubtitle}</p>
        <a className="mt-2 max-w-[350px] w-full m-auto text-[16px] font-semibold text-[#8649D1] h-[50px] flex justify-center items-center border border-[#8649D1] rounded-[100px] mb-2" href="https://app.morgis.com/auth/signup-guest/?email=1" target="_blank" rel="noopener noreferrer">
          {languageData.getconnected?.DiscoverMore}
          <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8L8 16L6.575 14.6L12.175 9L0 9L0 7L12.175 7L6.575 1.4L8 0L16 8Z" fill="#8649D1" />
          </svg>
        </a>
        <div className="SearchSectionCard !w-full mt-8">
          <div className="flex items-center">
            <img className="w-[56px] h-[56px] mr-2 rounded-[12px]" src={avatar1} alt="avatar1" />
            <h4>{languageData.getconnected?.instBCard1Name} </h4>
          </div>
          <div className="flex items-start mt-4">
            <img className="w-[12px] mr-2 pt-1" src={brackets} alt="brackets" />
            <p className="text-[16px] font-bold">{languageData.getconnected?.instBCard1Message}</p>
          </div>
          <Link to="https://www.morgis.com/primediciner?email=1" target="_blank">
          <button className="w-full h-[30px] flex justify-center items-center text-white bg-[#8649D1] text-[12px] font-semibold rounded-[100px] mt-4 ">{languageData.getconnected?.ConnectNow}</button>
          </Link>
        </div>
        {/* Repeating the card content */}
        <div className="SearchSectionCard !w-full mt-4">
          <div className="flex items-center">
            <img className="w-[56px] h-[56px] mr-2 rounded-[12px]" src={avatar2} alt="avatar2" />
            <h4>{languageData.getconnected?.instBCard1Name}</h4>
          </div>
          <div className="flex items-start mt-4">
            <img className="w-[12px] mr-2 pt-1" src={brackets} alt="brackets" />
            <p className="text-[16px] font-bold">{languageData.getconnected?.instBCard2Name}</p>
          </div>
          <Link to="https://www.morgis.com/contatelareina?email=1" target="_blank">
            <button className="w-full h-[30px] flex justify-center items-center text-white bg-[#8649D1] text-[12px] font-semibold rounded-[100px] mt-4 ">{languageData.getconnected?.ConnectNow}</button>
          </Link>
        </div>
        <div className="SearchSectionCard !w-full mt-4">
          <div className="flex items-center">
            <img className="w-[56px] h-[56px] mr-2 rounded-[12px]" src={avatar3} alt="avatar3" />
            <h4>{languageData.getconnected?.instBCard1Name}</h4>
          </div>
          <div className="flex items-start mt-4">
            <img className="w-[12px] mr-2 pt-1" src={brackets} alt="brackets" />
            <p className="text-[16px] font-bold">{languageData.getconnected?.instBCard3Name}</p>
          </div>
          <Link to="https://www.morgis.com/surocha55?email=1" target="_blank">
            <button className="w-full h-[30px] flex justify-center items-center text-white bg-[#8649D1] text-[12px] font-semibold rounded-[100px] mt-4">{languageData.getconnected?.ConnectNow}</button>
          </Link>
        </div>
      </div>
      <div className="recipe-section-cards">
        <div className="SearchSectionCard mr-2 flex flex-col !rounded-[16px] !p-1">
          <div className="flex items-center">
            <img className="w-[56px] h-[56px] mr-2 rounded-[12px]" src={avatar1} alt="avatar1" />
            <h4>{languageData.getconnected?.instBCard1Name}</h4>
          </div>
          <div className="flex items-start mt-4 flex-grow">
            <img className="w-[12px] mr-2 pt-1" src={brackets} alt="brackets" />
            <p className="text-[16px] font-bold text-[#00063D] leading-5">{languageData.getconnected?.instBCard1Message}</p>
          </div>
          <Link to="https://www.morgis.com/primediciner?email=1" target="_blank">
            <button className="w-full h-[30px] flex justify-center items-center text-white bg-[#8649D1] text-[12px] font-semibold rounded-[100px] mt-4 align-self-end">{languageData.getconnected?.ConnectNow}</button>
          </Link >
        </div>
        {/* Repeating the card content */}
        <div className="SearchSectionCard mr-2 flex flex-col !rounded-[16px] !p-1">
          <div className="flex items-center">
            <img className="w-[56px] h-[56px] mr-2 rounded-[12px]" src={avatar2} alt="avatar2" />
            <h4 >{languageData.getconnected?.instBCard2Name}</h4>
          </div>
          <div className="flex items-start mt-4 flex-grow">
            <img className="w-[12px] mr-2 pt-1" src={brackets} alt="brackets" />
            <p className="text-[16px] font-bold text-[#00063D] leading-5">{languageData.getconnected?.instBCard2Message}</p>
          </div>
          <Link to="https://www.morgis.com/contatelareina?email=1" target="_blank">
            <button className="w-full h-[30px] flex justify-center items-center text-white bg-[#8649D1] text-[12px] font-semibold rounded-[100px] mt-4 align-self-end">{languageData.getconnected?.ConnectNow}</button>
          </Link>
        </div>
        <div className="SearchSectionCard flex flex-col !rounded-[16px] !p-1">
          <div className="flex items-center">
            <img className="w-[56px] h-[56px] mr-2 rounded-[12px]" src={avatar3} alt="avatar3" />
            <h4 className="!text-[13px]">{languageData.getconnected?.instBCard3Name}</h4>
          </div>
          <div className="flex items-start mt-4 flex-grow">
            <img className="w-[12px] mr-2 pt-1" src={brackets} alt="brackets" />
            <p className="text-[16px] font-bold text-[#00063D] leading-5">{languageData.getconnected?.instBCard3Message}</p>
          </div>
          <Link to="https://www.morgis.com/surocha55?email=1" target="_blank">
            <button className="w-full h-[30px] flex justify-center items-center text-white bg-[#8649D1] text-[12px] font-semibold rounded-[100px] mt-4 align-self-end">{languageData.getconnected?.ConnectNow}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;