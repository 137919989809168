import React from "react";
import lifeTime from "../../Images/New/mhostA/lifeTime.png";
import { useLanguage } from "../../Helpers/LanguageContext";

const LifeTimeSection = () => {
  const { languageData } = useLanguage();

  return (
    <div className="container py-6 md:py-[100px]">
      <div className="flex flex-col-reverse md:flex-row-reverse justify-between items-center md:space-x-8">
        <img className="w-[600px] mb-8 md:mb-0 md:order-2 mt-6 md:mt-0" src={lifeTime} alt="lifeTime" />
        <div className="max-w-[440px] w-full md:pt-[100px] relative leading-[55px] font-bold inter z-10 md:order-1">
          <p className="text-[18px] md:text-[20px] font-bold text-[#8649D1] text-center md:text-left leading-6">
            {languageData.deeper?.LifeTimeTitle} <span className="text-[16px] tracking-[-2px]">{languageData.deeper?.LifeTimeTitleSmile}</span>
          </p>
          <h1 className="text-[24px] md:text-[32px] text-white leading-[28px] md:leading-10 text-center md:text-left mt-2">
            {languageData.deeper?.LifeTimeSubtitle}
          </h1>
          <h4 className="inter font-normal text-[14px] md:text-[18px] text-[#c2c2c2] leading-[19px] md:leading-[27px] mt-2 md:mt-[20px] pb-6 text-center md:text-left">
            {languageData.deeper?.LifeTimeDescription}
          </h4>
          <a href="https://app.morgis.com/auth/signup-guest/?email=1" target="_blank">
            <button className="max-w-[350px] m-auto md:m-[unset] w-full h-[50px] flex justify-center items-center text-white bg-[#8649D1] text-[16px] font-semibold rounded-[100px]">
              {languageData.deeper?.StartConnectionsButton}
              <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 16L0 8L1.4 6.575L7 12.175L7 0L9 0L9 12.175L14.6 6.575L16 8L8 16Z" fill="white" />
              </svg>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LifeTimeSection;
