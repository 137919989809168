import React from "react";
import logo from "../../Images/Logo.png";
import mobileLogo from "../../Images/Logo.png";
import heroImage from "../../Images/New/instA/hero.png";
import heroImageMobile from "../../Images/New/instA/heroMob.png";
import "../HeroSection/HeroSection.css";
import { useLanguage } from "../../Helpers/LanguageContext";

const HeroSectionNew = () => {

  const { languageData } = useLanguage();

  return (
    <>
      <div className="container pb-[80px] md:pb-[230px]">
        <div className="w-[111px] pt-[50px] hidden md:flex">
          <img src={logo} alt="Logotype" />
        </div>
        <div className="w-[64px] pt-[170px] md:hidden block m-auto">
          <img src={mobileLogo} alt="Logotype" />
        </div>
        <div className="max-w-[700px] w-full pt-6 md:pt-[100px] relative leading-[55px] font-bold inter">
          <p className="text-[18px] md:text-[20px] font-bold text-[#8649D1] text-center md:text-left my-3 md:my-0">
            {languageData.deeper?.HeroSectionMhostIntro}
          </p>
          <h1 className="text-[32px] md:text-[48px] text-white text-center md:text-left leading-[38px] md:leading-[57px] font-bold">{languageData.homepage?.HeroSectionTitle}</h1>
          <h4 className="inter font-light text-[14px] md:text-[24px] text-[#c2c2c2] leading-[19px] md:leading-[33px] md:mt-[25px] pb-4 md:pb-10 text-center md:text-left">{languageData.homepage?.HeroSectionSubtitle}</h4>
          <a href="https://app.morgis.com/auth/signup-guest/?email=1" target="_blank">
            <button className="max-w-[350px] m-auto md:m-[unset] w-full h-[50px] flex justify-center items-center text-white bg-[#8649D1] text-[16px] font-semibold rounded-[100px]">{languageData.homepage?.JoinNow}</button>
          </a>
        </div>
        <div className="hero-section-image w-full">
          <img src={heroImage} alt="Hero Section Image" />
        </div>
        <div className="hero-section-image-mobile">
          <img src={heroImageMobile} alt="Hero Section Image" />
        </div>

      </div>

    </>
  );
};

export default HeroSectionNew;
