import React from 'react';
import SignInSectoin from '../../Components/mhostA/SignInSectoin';
import HeroSectionMhostB from '../../Components/mhostB/HeroSectionMhostB';
import CompanySection from '../../Components/mhostB/CompanySection';


function MhostB() {
    return (
        <div>
            <HeroSectionMhostB  />
            <CompanySection />
            <SignInSectoin />
        </div>
    )
}

export default MhostB
