import React from "react";
import arrowLight from "../../Images/New/mhostA/arrowLight.svg";
import { useLanguage } from "../../Helpers/LanguageContext";

const FinalSection = () => {
  const { languageData } = useLanguage();

  return (
    <div>
      <div className="container md:flex justify-between items-center pb-[20px] md:py-[100px]">
        <div className="max-w-[440px] w-full relative leading-[55px] font-bold inter z-10">
          <p className="text-[18px] md:text-[20px] font-bold text-[#8649D1] text-center md:text-left">
            {languageData.deeper?.FinalSectionTitle}
          </p>
          <h1 className="text-[28px] md:text-[36px] text-white leading-[28px] md:leading-10 text-center md:text-left">
            {languageData.deeper?.FinalSectionSubtitle} <span className="text-[#9A85F5]">{languageData.deeper?.FinalSectionSubtitleSpan}</span>
          </h1>
          <div className="flex justify-end w-full">
            <img className="hidden md:block" src={arrowLight} alt="arrowLight" />
          </div>
        </div>
        <div className="max-w-[620px] w-full bg-mhostAfinalMob md:bg-mhostAfinal bg-no-repeat bg-contain bg-center sm:bg-cover h-[700px] md:h-[800px] cursor-pointer flex justify-center items-center my-6 md:my-0">
          
        </div>
      </div>
    </div>
  );
};

export default FinalSection;
