import React from "react";
import seekImage from "../../Images/seekImage.webp";
import { useLanguage } from "../../Helpers/LanguageContext";

const SignInSection = () => {
  const { languageData } = useLanguage();

  return (
    <div className="w-full bg-white rounded-t-[50px] rounded-tl-[50px] py-[50px] md:py-[100px]">
      <div className="container flex justify-center">
        <div className="max-w-[740px] w-full relative leading-[55px] font-bold inter z-10">
          <img className="w-[300px] m-auto" src={seekImage} alt="seekImage" />
          <p className="text-[24px] md:text-[32px] font-bold text-[#00063D] text-center my-8 leading-[28px]">
            {languageData.deeper?.SignUpTitle}
          </p>
          <a href="https://app.morgis.com/auth/signup-guest/?email=1" target="_blank">
            <button className="max-w-[350px] w-full h-[50px] flex justify-center items-center text-white bg-[#8649D1] text-[16px] font-semibold rounded-[100px] m-auto">
              {languageData.deeper?.JoinNow}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignInSection;
