import React, { useState, useRef } from 'react'
import { SwiperSlide, Swiper } from 'swiper/react'
import 'swiper/css';
import sliderImage1 from '../../Images/New/instA/slider2.png'
import sliderImage2 from '../../Images/New/instA/slider3.png'
import { Link } from 'react-router-dom';
import { useLanguage } from '../../Helpers/LanguageContext';

export default function CardsSlider({ mainSwiper, setActiveDetails, handlers, swiper, setSwiper }) {

  const {languageData} = useLanguage()

  const data = [
    {
      img: sliderImage1,
      name: languageData.homepage?.instASlide1Name,
      quote: languageData.homepage?.instASlide1Role,
      interests: languageData.homepage?.instASlide1Desc,
      hobbies: languageData.homepage?.instASlide1Hobby,
      nameBtn: 'Karina',
      link: 'https://www.morgis.com/contatelareina?email=1'
    },
    {
      img: sliderImage2,
      name: languageData.homepage?.instASlide2Name,
      quote: languageData.homepage?.instASlide2Role,
      interests: languageData.homepage?.instASlide2Desc,
      hobbies: languageData.homepage?.instASlide2Hobby,
      nameBtn: 'Suzana',
      link: 'https://www.morgis.com/surocha55?email=1'
    },
    {
      img: sliderImage1,
      name: languageData.homepage?.instASlide1Name,
      quote: languageData.homepage?.instASlide1Role,
      interests: languageData.homepage?.instASlide1Desc,
      hobbies: languageData.homepage?.instASlide1Hobby,
      nameBtn: 'Karina',
      link: 'https://www.morgis.com/contatelareina?email=1'
    },
    {
      img: sliderImage2,
      name: languageData.homepage?.instASlide2Name,
      quote: languageData.homepage?.instASlide2Role,
      interests: languageData.homepage?.instASlide2Desc,
      hobbies: languageData.homepage?.instASlide2Hobby,
      nameBtn: 'Suzana',
      link: 'https://www.morgis.com/surocha55?email=1'
    },
  ];



  const [activeSlide, setActiveSlide] = useState(0);

  let screenWidth = window.innerWidth;



  const touchStartX = useRef(null);

  const handleTouchStart = (event) => {
    touchStartX.current = event.touches[0].clientX;
  };

  const handleTouchMove = (event) => {
    if (touchStartX.current !== null) {
      const currentX = event.touches[0].clientX;
      const deltaX = currentX - touchStartX.current;

      if (deltaX > 0) {
        mainSwiper.slidePrev()
      } else if (deltaX < 0) {
        mainSwiper.slideNext()
      }


      touchStartX.current = null;
    }
  };

  return (
    <div className='relative '>
      <Swiper
        initialSlide={0}
        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
        draggable={false}

        spaceBetween={0}
        slidesPerView={2}
        centeredSlides={true}
        loop={true}
        onSlideChange={(swiper) => {
          const activeIndex = swiper.activeIndex;
          setActiveSlide(activeIndex);
          const slides = swiper.slides;

          slides.forEach((slide, index) => {
            let scaleValue;
            let zIndex;
            let right = 0;
            let filter = 0.5

            const distanceFromActive = Math.abs(activeIndex - index);
            if (index < activeIndex) {
              scaleValue = 0;
            } else if (index === activeIndex) {
              scaleValue = 1;
              zIndex = 5;
              right = screenWidth > 1024 ? 120 : 85
              filter = 1
            } else if (index === activeIndex + 1) {
              scaleValue = screenWidth > 1024 ? 0.7 : 0.7;
              zIndex = 4;
              right = screenWidth > 1024 ? 180 : 165
            }

            slide.style.transform = `scale(${scaleValue})`;
            slide.style.zIndex = zIndex;
            slide.style.right = `${right}px`
            slide.style.filter = `brightness(${filter})`


            if (distanceFromActive <= 1) {
              slide.style.transition = 'all .3s';
            } else {
              slide.style.transition = 'none';
            }
          });
        }}
      >
        {
          data.map((slide, index) => {
            return <>
              <SwiperSlide
                {...handlers}
                
                className='relative right-10 w-[170px] lg:w-[300px] h-[170px] lg:h-[300px] cursor-pointer ' key={index}>


                <div key={index} className='w-[300px] md:w-[370px] bg-white p-2 rounded-[24px] mx-auto'>

                  <img className='w-full rounded-[20px]' src={slide.img} alt="avatar" />
                  <div className='px-2'>
                    <p className='text-[#00063D] text-[18px] md:text-[24px] font-semibold pt-2'>{slide.name}</p>
                    <p className='text-[#00063db7] text-[14px] md:text-[16px] font-normal leading-5 pt-2'>{slide.quote}</p>
                    <div className='flex items-center pt-2'>
                      <svg className='w-[16px] md:w-[21px]' viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.97508 17.9835L5.67751 17.8318L5.35075 17.9011L1.25546 18.7694L2.15162 14.9231L2.23462 14.5668L2.0558 14.2478C1.32225 12.9389 0.941125 11.4803 0.94346 10.0015V10C0.94346 5.03948 5.17178 0.943448 10.4828 0.943448C15.7938 0.943448 20.0221 5.03948 20.0221 10C20.0221 14.9605 15.7938 19.0566 10.4828 19.0566L10.4814 19.0566C8.90736 19.0588 7.35908 18.6894 5.97508 17.9835Z" stroke="url(#paint0_linear_50_72)" stroke-width="1.8869" />
                        <path d="M5.97508 17.9835L5.67751 17.8318L5.35075 17.9011L1.25546 18.7694L2.15162 14.9231L2.23462 14.5668L2.0558 14.2478C1.32225 12.9389 0.941125 11.4803 0.94346 10.0015V10C0.94346 5.03948 5.17178 0.943448 10.4828 0.943448C15.7938 0.943448 20.0221 5.03948 20.0221 10C20.0221 14.9605 15.7938 19.0566 10.4828 19.0566L10.4814 19.0566C8.90736 19.0588 7.35908 18.6894 5.97508 17.9835Z" stroke="#00063D" stroke-width="1.8869" />
                        <defs>
                          <linearGradient id="paint0_linear_50_72" x1="10.4828" y1="20" x2="10.4828" y2="0" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#8649D1" />
                            <stop offset="0.385417" stopColor="#8B6DE8" />
                            <stop offset="1" stopColor="#8F92FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <p className='text-[#00063db7] text-[14px] md:text-[16px] font-medium leading-5 ml-2 truncate'>{slide.interests}</p>
                    </div>
                    <div className='flex items-center pt-2'>
                      <svg className='w-[16px] md:w-[21px]' viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_50_74)">
                          <path d="M6.44269 7.77111V7.55172H5.39442H2H0.951724V8.6V19V20.0483H2H5.39442H6.04335H6.44269H7.09163H15.5777C16.1158 20.0483 16.6185 19.8907 17.0689 19.6075C17.5022 19.3353 17.8659 18.9686 18.0936 18.4916L18.0984 18.4815L18.103 18.4713L20.6477 12.8338C20.6479 12.8333 20.6482 12.8327 20.6484 12.8322C20.7258 12.6617 20.781 12.4848 20.8166 12.3045C20.8493 12.1414 20.869 11.9723 20.869 11.8V10.2C20.869 9.45595 20.5584 8.81476 20.0306 8.31724C19.5033 7.8201 18.8524 7.55172 18.1235 7.55172H14.8651L15.4445 5.39159L15.4803 5.25815V5.12V4.84C15.4803 4.5404 15.4154 4.25596 15.3116 3.99403L15.3105 3.99106C15.2045 3.72594 15.0471 3.45917 14.8117 3.23724L13.7509 2.23724L13.0319 1.55934L12.3128 2.23724L6.44269 7.77111ZM14.3371 4.37998L14.337 4.38L14.3371 4.37998Z" stroke="#00063D" stroke-width="2.09655" />
                        </g>
                        <defs>
                          <clipPath id="clip0_50_74">
                            <rect width="22" height="21" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p className='text-[#00063db7] text-[14px] md:text-[16px] font-medium leading-5 ml-2'>{slide.hobbies}</p>
                    </div>
                    <Link target='_blank' to={slide.link}>
                      <button className="w-full py-1 md:py-0 md:h-[50px] flex justify-center items-center text-white bg-[#8649D1] text-[16px] font-semibold rounded-[100px] my-3 md:mt-5 md:mb-3">{languageData.homepage?.ConnectWith} {slide.nameBtn.split(',')[0]}</button>
                    </Link>
                  </div>
                </div>



              </SwiperSlide>
            </>
          })
        }
      </Swiper>


    </div>
  );
}
