import React from 'react'
import HeroSectionMhost from '../../Components/mhostA/HeroSectionMhost';
import LifeTimeSection from '../../Components/mhostA/LifeTimeSection';
import FinalSection from '../../Components/mhostA/FinalSection';
import SignInSectoin from '../../Components/mhostA/SignInSectoin';


function MhostA() {
    return (
        <div>
            <HeroSectionMhost  />
            <LifeTimeSection />
            <FinalSection />
            <SignInSectoin />
        </div>
    )
}

export default MhostA
