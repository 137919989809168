import React, { useState, useRef } from 'react'
import CardsSlider from './CardsSlider'
import { useSwipeable } from 'react-swipeable';
import { useLanguage } from '../../Helpers/LanguageContext';

function ConnectSection() {
    const mainSwiper = useRef(null);
    const {languageData} = useLanguage()

    const [activeDetails, setActiveDetails] = useState(false);
    const [swiper, setSwiper] = useState(null);
    const handlers = useSwipeable({

        onSwipedLeft: (eventData) => {

            mainSwiper.slideNext()
            setActiveDetails(false)
        },
        onSwipedRight: (eventData) => {

            mainSwiper.slidePrev()
            setActiveDetails(false)
        },

        onTouchStartOrOnMouseDown: (eventData) => {
            alert('touch')
        },
    })
    return (

        <div className=" w-full flex md:pt-[100px] items-center">
            <div className="container md:flex justify-between pb-12">
                <div className="max-w-[500px] w-full md:pt-[100px] relative leading-[55px] font-bold inter">
                    <h1 className="text-[24px] md:text-[36px] text-white text-center md:text-left leading-[28px] md:leading-[43px]">{languageData.homepage?.ConnectTitle} <span className='text-[#8649D1]'>{languageData.homepage?.ConnectTitleSpan}</span></h1>
                    <div className=' max-w-[110px] justify-between mt-4 hidden md:flex '>
                        <svg onClick={e => {
                            swiper.slidePrev()
                        }} className=' cursor-pointer' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="41" y="41" width="40" height="40" rx="20" transform="rotate(-180 41 41)" stroke="white" />
                            <path d="M12 21L20 13L21.425 14.4L15.825 20L28 20L28 22L15.825 22L21.425 27.6L20 29L12 21Z" fill="white" />
                        </svg>
                        <svg onClick={e => {
                            swiper.slideNext()
                        }} className=' cursor-pointer' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="40" height="40" rx="20" stroke="white" />
                            <path d="M30 21L22 29L20.575 27.6L26.175 22L14 22L14 20L26.175 20L20.575 14.4L22 13L30 21Z" fill="white" />
                        </svg>
                    </div>
                </div>
                <div className='max-w-[350px] md:max-w-[550px] m-auto w-full relative left-0 mt-6 md:mt-0'>
                <CardsSlider setActiveDetails={setActiveDetails} mainSwiper={mainSwiper} handlers={handlers} swiper={swiper} setSwiper={setSwiper} />
                </div>
            </div>
        </div>

    )
}

export default ConnectSection
