import React, { useContext } from "react";
import avatar1 from "../../Images/New/instA/slider1.png";
import avatar2 from "../../Images/New/instA/slider2.png";
import avatar3 from "../../Images/New/instA/slider3.png";
import brackets from "../../Images/New/InstB/brackets.svg";
import bg from "../../Images/New/mhostB/bgCompany.png";
import { useLanguage } from "../../Helpers/LanguageContext";
import { Link } from "react-router-dom";

const CompanySection = ({ }) => {

const {languageData} = useLanguage();

  return (
    <div className="w-full py-[50px] md:py-[270px] relative" id="hosts">
      <div className="container md:flex justify-between items-center ">
        <div className="max-w-[440px] m-auto w-full relative leading-[55px] font-bold inter z-10">

          <h1 className="text-[24px] md:text-[32px] text-white leading-[28px] md:leading-10 text-center md:text-left">{languageData.deeper2?.InstBSearchTitle}</h1>
          <h4 className="font-normal text-[14px] md:text-[18px] text-[#c2c2c2] leading-[19px] md:leading-[27px] mt-2 md:mt-[30px] pb-6 text-center md:text-left">{languageData.deeper2?.InstBSearchSubtitle}</h4>
          <a href="https://app.morgis.com/auth/signup-guest/?email=1" target="_blank">
            <button className="max-w-[350px] w-full m-auto md:m-[unset] h-[50px] flex justify-center items-center text-white bg-[#8649D1] text-[16px] font-semibold rounded-[100px]">{languageData.deeper2?.DiscoverMore}
              <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8L8 16L6.575 14.6L12.175 9L-3.0598e-07 9L-3.93402e-07 7L12.175 7L6.575 1.4L8 -3.49691e-07L16 8Z" fill="white" />
              </svg>
            </button>
          </a>

        </div>
        
        <div className="md:flex max-w-[600px] w-full relative z-10 mt-[250px] md:mt-0">
          <div className="SearchSectionCard mr-2 flex flex-col !rounded-[16px] !p-1">
            <div className="flex items-center">
              <img className="w-[56px] h-[56px] mr-2 rounded-[12px]" src={avatar1} alt="avatar1" />
              <h4>{languageData.deeper2?.instBCard1Name}</h4>
            </div>
            <div className="flex items-start mt-4 flex-grow">
              <img className="w-[12px] mr-2 pt-1" src={brackets} alt="brackets" />
              <p className="text-[16px] font-bold text-[#00063D] leading-5">{languageData.deeper2?.instBCard1Message}</p>
            </div>
            <Link to="https://www.morgis.com/primediciner " target="_blank">
            <button className="w-full h-[30px] flex justify-center items-center text-white bg-[#8649D1] text-[12px] font-semibold rounded-[100px] mt-4 align-self-end">{languageData.getconnected?.ConnectNow}</button>
            </Link>
          </div>
          <div className="SearchSectionCard mr-2 flex flex-col !rounded-[16px] !p-1">
            <div className="flex items-center">
              <img className="w-[56px] h-[56px] mr-2 rounded-[12px]" src={avatar2} alt="avatar2" />
              <h4 >{languageData.deeper2?.instBCard2Name}</h4>
            </div>
            <div className="flex items-start mt-4 flex-grow">
              <img className="w-[12px] mr-2 pt-1" src={brackets} alt="brackets" />
              <p className="text-[16px] font-bold text-[#00063D] leading-5">{languageData.deeper2?.instBCard2Message}</p>
            </div>
            <Link to="https://www.morgis.com/contatelareina " target="_blank">
            <button className="w-full h-[30px] flex justify-center items-center text-white bg-[#8649D1] text-[12px] font-semibold rounded-[100px] mt-4 align-self-end">{languageData.getconnected?.ConnectNow}</button>
            </Link>
          </div>
          <div className="SearchSectionCard flex flex-col !rounded-[16px] !p-1">
            <div className="flex items-center">
              <img className="w-[56px] h-[56px] mr-2 rounded-[12px]" src={avatar3} alt="avatar3" />
              <h4 className="!text-[13px]">{languageData.deeper2?.instBCard3Name}</h4>
            </div>
            <div className="flex items-start mt-4 flex-grow">
              <img className="w-[12px] mr-2 pt-1" src={brackets} alt="brackets" />
              <p className="text-[16px] font-bold text-[#00063D] leading-5">{languageData.deeper2?.instBCard3Message}</p>
            </div>
            <Link to="https://www.morgis.com/surocha55  " target="_blank">
            <button className="w-full h-[30px] flex justify-center items-center text-white bg-[#8649D1] text-[12px] font-semibold rounded-[100px] mt-4 align-self-end">{languageData.getconnected?.ConnectNow}</button>
            </Link>
          </div>
        </div>
        <img className="hidden md:block absolute right-0 max-w-[650px] w-full" src={bg} alt="bgCompany" />
      </div>
      <img className="md:hidden block absolute top-[350px] md:right-0 max-w-[650px] w-full rounded-[32px]" src={bg} alt="bgCompany" />
    </div>
  );
};

export default CompanySection;
